import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OrderDepo } from 'src/app/model/order.depo';
import { Order } from 'src/app/model/order.model';

@Component({
  selector: 'checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {


  orderSent: boolean = false;
  submitted: boolean = false;

  constructor(public order: Order, private orderDepo: OrderDepo ) { }

  ngOnInit() {
  }
  submitOrder(form:NgForm) {
    this.submitted = true;
    if(form.valid) {
      this.orderDepo.saveOrder(this.order)
      .subscribe(order=> {
        this.order.clearOrder();
        this.orderSent = true;
        this.submitted = false;
      })
    }
  }
}
