import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Cart } from "../model/card.model";
import { CategoryDepo } from "../model/category.depo";
import { Category } from "../model/category.model";
import { Product } from "../model/product.model";
import { UrunDepo } from "../model/urun.depo";

@Component({
    selector: 'shop',
    templateUrl: 'shop.component.html'
})

export class ShopComponent {
    public productsPerPage = 2;
    public selectedPage = 1;
    public selectedProducts: Product[] = [];
    public selectedCategory: Category = null;

    
    constructor(
        private urunDepo: UrunDepo,
        ) {}

        get products(): Product[] {
            let index = (this.selectedPage - 1) * this.productsPerPage;

            this.selectedProducts = this.urunDepo
                                    .getProducts(this.selectedCategory)
            return this.selectedProducts
                    .slice(index, index + this.productsPerPage);
        }

        get pageNumbers(): number[] {
            return Array(Math.ceil(this.urunDepo
                .getProducts(this.selectedCategory).length/this.productsPerPage))
                .fill(0)
                .map((a,i) => i + 1);
        }

        changePage(p: number) {
            this.selectedPage = p;
        }

        changePageSize(size: number) {
            this.productsPerPage = size;
            this.changePage(1);
        }

        getCategory(category: Category) {
            this.selectedCategory = category;
        }

    }

