import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CategoryDepo } from 'src/app/model/category.depo';
import { Category } from 'src/app/model/category.model';

@Component({
  selector: 'category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

  public selectedCategory: Category = null;

  @Output() category = new EventEmitter<Category>();

  constructor(
    private categoryDepo: CategoryDepo ) { }

  ngOnInit() {
  }

  get categories(): Category[] {
    return this.categoryDepo.getCategories();
  }

  changeCategory(newCategory?: Category) {
    this.selectedCategory = newCategory;
    this.category.emit(newCategory);
  }

}
