import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RestService } from './rest.service';
import { UrunDepo } from './urun.depo';
import { CategoryDepo } from './category.depo';
import { Cart } from './card.model';
import { Order } from './order.model';
import { OrderDepo } from './order.depo';
import { Authservice } from './auth.service';

@NgModule({
    imports: [HttpClientModule],
    providers:[RestService, UrunDepo, CategoryDepo, Cart, Order, OrderDepo, Authservice]
})

export class ModelModul {

}