import { Injectable, OnInit } from '@angular/core';
import { Category } from './category.model';
import { RestService } from './rest.service';

@Injectable()

export class CategoryDepo implements OnInit {
    private categories: Category[] = [];

    constructor(private restServis: RestService){ 
        this.restServis
        .getCategories()
        .subscribe(categories=> this.categories = this.categories);
    
    }

    ngOnInit(){
        }

    getCategory(id: number): Category {
        return this.categories.find(i => i.id == id);
    }

    getCategories(): Category[] {
        return this.categories;
    }

    saveCategory(category: Category) {
        if(category.id == null || category.id == 0) {
            this.restServis.addCategory(category)
            .subscribe(p=> this.categories.push(p));
        }else {
            this.restServis.updateCategory(category)
            .subscribe(p=> {
                this.categories.splice(this.categories.findIndex(p=> p.id == category.id), 1, category);
            })
        }
    }
    deleteCategory(category: Category) {
        this.restServis.deleteCategory(category)
        .subscribe(p=> this.categories.splice(this.categories.findIndex(p=> p.id==category.id),1));
    }
}