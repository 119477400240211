import { Injectable, OnInit } from '@angular/core';
import { Category } from './category.model';
import { Product } from './product.model';
import { RestService } from './rest.service';


@Injectable()

export class UrunDepo implements OnInit {
    private products: Product[] = [];

    constructor(private restServis: RestService){ 
        this.restServis
        .getProducts()
        .subscribe(products=> this.products = products);
    }

    ngOnInit(){
        
    }

    getProduct(id: number): Product {
        return this.products.find(i => i.id == id);
    }

    getProducts(category: Category = null): Product[] {
        if(category)
            return this.products.filter(p=> p.category == category.name);
        else
            return this.products;
    }

    saveProduct(product: Product) {
        if(product.id == null || product.id == 0) {
            this.restServis.addProduct(product)
            .subscribe(p=> this.products.push(p));
        }else {
            this.restServis.updateProduct(product)
            .subscribe(p=> {
                this.products.splice(this.products.findIndex(p=> p.id == product.id), 1, product);
            })
        }
    }
    deleteProduct(product: Product) {
        this.restServis.deleteProduct(product)
        .subscribe(p=> this.products.splice(this.products.findIndex(p=> p.id==product.id),1));
    }
    
}